<template>
  <div class="w-100 static-content-container">
    <div v-if="updatedAt && showUpdatedAt" class="mb-3 text-white">
      Last updated at: {{ $dateHelpers.formatDate(updatedAt) }}
      {{ $dateHelpers.formatTime(updatedAt) }}
    </div>
    <quill-editor v-if="isAdmin && showEdit" v-model="content" />
    <div v-if="!showEdit && content" v-html="content"></div>
    <slot v-if="!showEdit && (!content || content === '')" />
    <div
      class="static-content-float"
      v-if="isAdmin"
      :style="showEdit ? 'opacity: 1;' : ''"
    >
      <save-button v-if="showEdit" :disabled="loading" @click="onSave" />
      <edit-button :disabled="loading" @click="showEdit = !showEdit" />
    </div>
  </div>
</template>

<script>
import { quillEditor } from "vue-quill-editor";

// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { mapActions } from "vuex";
import EditButton from "@/layouts/components/buttons/EditButton";
import SaveButton from "@/layouts/components/buttons/SaveButton";

//TODO alt support for images
export default {
  name: "StaticContent",
  props: {
    contentCode: {
      type: String,
      required: true,
    },
    showUpdatedAt: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  components: {
    SaveButton,
    quillEditor,
    EditButton,
  },
  data() {
    return {
      content: "",
      updatedAt: "",
      showEdit: false,
    };
  },
  mounted() {
    this.refreshStaticContentFromStore();
  },
  computed: {
    loading() {
      return this.$store.getters["staticContents/isLoading"] ?? false;
    },

    isAdmin() {
      return this.$store.getters["auth/isAdmin"] ?? false;
    },
  },
  methods: {
    ...mapActions({
      updateStaticContent: "staticContents/updateStaticContent",
    }),
    refreshStaticContentFromStore() {
      const staticContent = this.$store.getters[
        "staticContents/getContents"
      ]?.find(({ code }) => code === this.contentCode);
      this.content = staticContent?.content;
      this.updatedAt = staticContent?.updated_at;
    },
    async onSave() {
      await this.updateStaticContent({
        code: this.contentCode,
        content: this.content,
      });
      this.refreshStaticContentFromStore();
      this.showEdit = false;
    },
  },
};
</script>

<style lang="scss">
.static-content-float {
  position: absolute;
  left: 1rem;
  bottom: 1rem;
  opacity: 0.25;
}
.static-content-container:hover > .static-content-float {
  opacity: 1;
}
.dark-layout .ql-stroke {
  fill: none;
  stroke: #f8f8ff !important;
}
.dark-layout .ql-picker-options .ql-stroke {
  fill: none;
  stroke: black !important;
}
.dark-layout .ql-fill {
  fill: #f8f8ff !important;
  stroke: none;
}
.dark-layout .ql-picker {
  color: #f8f8ff !important;
}
.dark-layout .ql-picker-item {
  color: black;
}
</style>
